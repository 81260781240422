<template>
    <div id="jobDetail">
        <div class="mcontainer">
            <div class="card">
                <div class="h-44 mb-4 md:h-72 overflow-hidden relative rounded-t-lg w-full">
                    <img :src="'https://fbeubaya.id/assets/img/events/'+event.image" alt="" class="w-full h-full absolute inset-0 object-cover">
                </div>
                <div class="p-4">
                    <h1 class="lg:text-2xl text-xl font-semibold mb-6">{{ event.title }}</h1>
                    <div class="flex items-center space-x-3 my-3 pb-4 border-b">
                        <img :src="require('@/assets/images/avatars/avatar-1.jpg')" class="w-10 h-10 rounded-full">
                        <div>
                            <div class="text-base font-semibold"> Administrator </div>
                            <div class="text-xs"> Published on {{ event.date }} <Icon icon="fontisto:map-marker-alt" style="margin-top: -3px;" class="mr-1 d-inline ml-2" /> {{ event.location }}</div>
                        </div>
                        <a :href="'https://fbeubaya.id/events/register/' + event.nomor" target="_blank" style="margin-left: auto;" class="btn btn-primary" v-if="event.expired !== '1' && event.show_button === '1'">Register</a>
                    </div>
                    <div class="space-y-3" v-html="event.description">
                    </div>
                    <br><br>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { Icon } from '@iconify/vue2'

    export default {
        components: {
            Icon
        },
        data () {
            return {
                event: null
            }
        },
        created () {
            this.fetchData()
        },
        methods: {
            fetchData () {
                const $this = this
                axios.get('https://fbeubaya.id/api/get_event_detail/' + this.$route.params.code).then(function (response) {
                    $this.event = response.data
                })
            }
        }
    }
</script>
